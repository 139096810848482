import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Stack } from '@mui/joy';
import Box from '@mui/joy/Box';
import { useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { LayoutContext } from '~/utils/contexts';
import Map from '../Map';
import Header from './Header';
import Sidebar, { SidebarItem, SidebarItems } from './Sidebar';

const CampaignsLayout = () => {
	const $content = useRef(null);
	const contentId = 'merlin-cloud-app-content';

	const [hideMap, setHideMap] = useState(false);

	return (
		<LayoutContext.Provider
			value={{
				$content,
				contentId,
			}}
		>
			<Stack sx={{ height: '100dvh' }}>
				<Header></Header>
				<Stack direction="row" flexGrow={1} minWidth={0} minHeight={0}>
					<Sidebar>
						<SidebarItems>
							<SidebarItem
								name="Home"
								path="/"
								icon={<FontAwesomeIcon icon={['fasr', 'home']} />}
							/>

							<SidebarItem
								name="Locations"
								path="/locations"
								icon={<FontAwesomeIcon icon={['fasr', 'location']} />}
							/>

							<SidebarItem
								name="Cameras"
								path="/cameras"
								icon={<FontAwesomeIcon icon={['fasr', 'camera-security']} />}
							/>
							<SidebarItem
								name="Kiosks"
								path="/kiosks"
								icon={<FontAwesomeIcon icon={['fasr', 'tablet']} />}
							/>
							<SidebarItem
								name="Campaigns"
								path="/campaigns"
								icon={<FontAwesomeIcon icon={['fasr', 'files']} />}
							/>
						</SidebarItems>

						<SidebarItems bottom>
							<SidebarItem
								name="Support"
								path="/support"
								icon={<FontAwesomeIcon icon={['fasr', 'life-ring']} />}
							/>
							<SidebarItem
								name="Settings"
								path="/settings/profile"
								icon={<FontAwesomeIcon icon={['fasr', 'cog']} />}
							/>
						</SidebarItems>
					</Sidebar>
					<Stack
						flexGrow={1}
						minWidth={0}
						minHeight={0}
						overflow="auto"
						height="100%"
						ref={$content}
						id={contentId}
						position="relative"
						component="main"
						marginLeft={{
							xs: `calc(var(--Sidebar-width, 250px) * -1)`,
							md: 0,
						}}
						marginRight={{
							xs: `calc(var(--MapSidebar-width, 450px) * -1)`,
							md: hideMap ? `calc(var(--MapSidebar-width, 450px) * -1)` : 0,
						}}
					>
						<Box
							sx={{
								px: {
									xs: 2,
									md: 3,
								},
								pt: {
									xs: 1,
									md: 2,
								},
								pb: {
									xs: 2,
									sm: 2,
									md: 3,
								},
							}}
						>
							<Container disableGutters maxWidth="xxl">
								<Outlet />
							</Container>
						</Box>
					</Stack>
					<Sidebar
						disableOverlay
						toggleOpen={hideMap}
						side="right"
						sidebarWidth={450}
						id="Map"
						sx={(theme) => ({
							my: -2,
						})}
					>
						<Map
							animate
							isLoading={false}
							zoom={5}
							coordinates={[51.5072, 0.1276]}
							style={{
								position: 'relative',
								borderRadius: 0,
								borderLeft: '1px solid',
								borderColor: 'var(--merlin-palette-divider, #30D98A)',
								width: '100%',
								height: '100%',
							}}
						/>
					</Sidebar>
					<Button
						onClick={() => setHideMap((prev) => !prev)}
						startDecorator={<FontAwesomeIcon icon={['fasr', 'globe-americas']} />}
						variant="solid"
						color="neutral"
						sx={(theme) => ({
							right: !hideMap ? `50%` : 'calc(50% - 250px)',
							display: { xs: 'none', md: 'flex' },
							border: `1px solid ${theme.vars.palette.neutral[600]}`,
							borderRadius: '20px',
							position: 'fixed',
							padding: '8px, 16px, 8px, 16px',
							bottom: 16,
							transform: !hideMap ? 'translateX(-35%)' : 'translateX(-50%)',
						})}
					>
						{hideMap ? 'Show map' : 'Hide map'}
					</Button>
				</Stack>
			</Stack>
			{/* <Footer /> */}
		</LayoutContext.Provider>
	);
};

export default CampaignsLayout;
