import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Stack } from '@mui/joy';
import Box from '@mui/joy/Box';
import { useRef } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import Header, { HeaderCrumb, HeaderCrumbs } from '~/components/Layout/Header';
import Sidebar, { SidebarItem, SidebarItems } from '~/components/Layout/Sidebar';
import { useAuth } from '~/hooks/auth';
import { IOrganisation } from '~/models/api/organisation';
import { useGet } from '~/utils/api';
import api from '~/utils/api/endpoints';
import { LayoutContext } from '~/utils/contexts';

const SettingsLayout = () => {
	const { organisation, organisations, organisationLoading } = useAuth();
	const { id: orgId } = useParams();

	const $content = useRef(null);
	const contentId = 'merlin-cloud-app-content';

	const pathname = useLocation().pathname?.split('/');

	const { data: currentOrg, isLoading } = useGet<IOrganisation>(
		api.organisations.organisation(orgId ?? '')
	);

	return (
		<LayoutContext.Provider
			value={{
				$content,
				contentId,
			}}
		>
			<Stack sx={{ height: '100dvh' }}>
				<Header>
					<HeaderCrumbs>
						<HeaderCrumb text="Settings" />
					</HeaderCrumbs>
				</Header>

				<Stack direction="row" flexGrow={1} minWidth={0} minHeight={0}>
					<Sidebar>
						<SidebarItems>
							<SidebarItem
								disableActive
								name={`Back to ${organisation?.name ?? ''}`}
								path="/"
								icon={<FontAwesomeIcon icon={['fasr', 'arrow-left-long']} />}
							/>
							<SidebarItem
								name="Profile"
								path={`/settings/profile`}
								icon={<FontAwesomeIcon icon={['fasr', 'user']} />}
							/>
							<SidebarItem
								name="Security"
								path={`/settings/security`}
								icon={<FontAwesomeIcon icon={['fasr', 'lock']} />}
							/>
							<SidebarItem
								name="Organisations"
								path={`/settings/organisations`}
								icon={<FontAwesomeIcon icon={['fasr', 'layer-group']} />}
							/>
							{/* <SidebarItem
								name="Billing"
								path={`/settings/billing`}
								icon={<FontAwesomeIcon icon={['fasr', 'credit-card']} />}
							/> */}
							<SidebarItem
								name="Plans"
								path={`/settings/plan`}
								icon={<FontAwesomeIcon icon={['fasr', 'boxes']} />}
							/>
						</SidebarItems>

						<SidebarItems bottom>
							<SidebarItem
								name="Support"
								path="/support"
								icon={<FontAwesomeIcon icon={['fasr', 'life-ring']} />}
							/>
						</SidebarItems>
					</Sidebar>

					<Stack
						flexGrow={1}
						minWidth={0}
						minHeight={0}
						overflow="auto"
						height="100%"
						ref={$content}
						id={contentId}
						position="relative"
						component="main"
						marginLeft={{
							xs: `calc(var(--Sidebar-width, 250px) * -1)`,
							md: 0,
						}}
					>
						<Box
							sx={{
								px: {
									xs: 2,
									md: 4,
								},
								pt: {
									xs: 1,
									md: 2,
								},
								pb: {
									xs: 2,
									sm: 2,
									md: 3,
								},
							}}
						>
							<Container disableGutters maxWidth="xxl">
								<Outlet />
							</Container>
						</Box>
					</Stack>
				</Stack>
			</Stack>
			{/* <Footer /> */}
		</LayoutContext.Provider>
	);
};

export default SettingsLayout;
