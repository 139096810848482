import Box from '@mui/joy/Box';
import { useRef } from 'react';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';
import Sidebar, { SidebarItem, SidebarItems } from '~/components/Layout/Sidebar';
import Header, { HeaderCrumb, HeaderCrumbs } from '~/components/Layout/Header';
import { LayoutContext } from '~/utils/contexts';
import { Container, Stack } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '~/hooks/auth';
import { useGet } from '~/utils/api';
import { IKiosk, IKiosksResponse } from '~/models/api/kiosk';
import api from '~/utils/api/endpoints';

const KioskLayout = () => {
	const { organisation } = useAuth();
	const { id: kioskId } = useParams();
	const $content = useRef(null);
	const contentId = 'merlin-cloud-app-content';

	const [searchParams] = useSearchParams();
	const {
		data: kiosk,
		isLoading: isLoadingKiosk,
		error,
	} = useGet<IKiosk>(api.kiosk, {
		params: { kioskId },
	});

	const { data: kiosks, isLoading: isLoadingKiosks } = useGet<IKiosksResponse>(api.kiosks, {
		params: { organisatiokionId: organisation?.id, pageNum: 1, perPage: 100 },
	});

	return (
		<LayoutContext.Provider
			value={{
				$content,
				contentId,
			}}
		>
			<Stack sx={{ height: '100dvh' }}>
				<Header>
					<HeaderCrumbs>
						<HeaderCrumb
							icon={<FontAwesomeIcon icon={['fasr', 'tablet-button']} />}
							text={kiosk?.name ?? ''}
							isLoading={isLoadingKiosk || isLoadingKiosks}
							options={kiosks?.results
								?.filter((filterKiosk) => filterKiosk.name !== kiosk?.name)
								?.map((kiosk) => ({
									label: kiosk.name,
									link: `/kiosks/${kiosk.id}`,
								}))}
						/>
					</HeaderCrumbs>
				</Header>
				<Stack direction="row" flexGrow={1} minWidth={0} minHeight={0}>
					<Sidebar>
						<SidebarItems>
							<SidebarItem
								disableActive
								name={`Back to Kiosks`}
								path="/kiosks"
								icon={<FontAwesomeIcon icon={['fasr', 'arrow-left-long']} />}
							/>
							<SidebarItem
								name="Kiosk"
								root={`/kiosks/${kioskId}`}
								path={`/kiosks/${kioskId}`}
								icon={<FontAwesomeIcon icon={['fasr', 'tablet-button']} />}
							/>
							<SidebarItem
								name="Analytics"
								path={`/kiosks/${kioskId}/analytics?${searchParams}`}
								icon={<FontAwesomeIcon icon={['fasr', 'chart-pie-simple']} />}
							/>
						</SidebarItems>

						<SidebarItems bottom>
							<SidebarItem
								name="Support"
								path="/support"
								icon={<FontAwesomeIcon icon={['fasr', 'life-ring']} />}
							/>
							<SidebarItem
								name="Settings"
								path="/settings/profile"
								icon={<FontAwesomeIcon icon={['fasr', 'cog']} />}
							/>
						</SidebarItems>
					</Sidebar>

					<Stack
						flexGrow={1}
						minWidth={0}
						minHeight={0}
						overflow="auto"
						height="100%"
						ref={$content}
						id={contentId}
						position="relative"
						component="main"
						marginLeft={{
							xs: `calc(var(--Sidebar-width, 250px) * -1)`,
							md: 0,
						}}
					>
						<Box
							sx={{
								px: {
									xs: 2,
									md: 4,
								},
								pt: {
									xs: 1,
									md: 2,
								},
								pb: {
									xs: 2,
									sm: 2,
									md: 3,
								},
							}}
						>
							<Container disableGutters maxWidth="xxl">
								<Outlet />
							</Container>
						</Box>
					</Stack>
				</Stack>
			</Stack>
			{/* <Footer /> */}
		</LayoutContext.Provider>
	);
};

export default KioskLayout;
