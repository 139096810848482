import { lazy } from 'react';
import ConversionLayout from '~/components/ConversionLayout';
import Layout from '~/components/Layout';
import CameraLayout from '~/components/Layout/Camera/Layout';
import CampaignLayout from '~/components/Layout/CampaignLayout';
import CampaignsLayout from '~/components/Layout/CampaignsLayout';
import KioskLayout from '~/components/Layout/KioskLayout';
import LocationLayout from '~/components/Layout/Location/Layout';
import type { PreRoute } from '~/models/route';
import SettingsLayout from '~/pages/settings/SettingsLayout';

const Offline = lazy(() => import('../pages/offline'));
const Private = lazy(() => import('../components/Private'));
const Protected = lazy(() => import('../components/Protected'));
const Login = lazy(() => import('../pages/login'));
const LoginGoogle = lazy(() => import('../pages/login-google'));
const Logout = lazy(() => import('../pages/logout'));
const ResetPassword = lazy(() => import('../pages/reset-password'));
const ConfirmPassword = lazy(() => import('../pages/confirm_password'));
const ChangeEmail = lazy(() => import('../pages/change-email'));
const Home = lazy(() => import('../pages/index'));
const Locations = lazy(() => import('../pages/locations/index'));
const Location = lazy(() => import('../pages/locations/location'));
const LocationAnalytics = lazy(() => import('../pages/locations/location_analytics'));
const LocationCameras = lazy(() => import('../pages/locations/location_cameras'));
const Kiosks = lazy(() => import('../pages/kiosks/kiosks'));
const Kiosk = lazy(() => import('../pages/kiosks/kiosk'));
const KioskAnalytics = lazy(() => import('../pages/kiosks/kiosk_analytics'));
const KioskSession = lazy(() => import('../pages/kiosks/kiosk_session'));

const Campaigns = lazy(() => import('../pages/campaigns/campaigns'));
const Campaign = lazy(() => import('../pages/campaigns/campaign'));
const CampaignEditor = lazy(() => import('../pages/campaigns/campaign_editor'));

const Cameras = lazy(() => import('../pages/cameras/index'));
const Camera = lazy(() => import('../pages/cameras/camera'));
const CameraAnalytics = lazy(() => import('../pages/cameras/camera_analytics'));
const SettingsOverview = lazy(() => import('../pages/settings/settings'));
const SettingsSecurity = lazy(() => import('../pages/settings/security'));
const SettingsBilling = lazy(() => import('../pages/settings/billing'));
const SettingsSupport = lazy(() => import('../pages/settings/support'));
const SettingsPlans = lazy(() => import('../pages/settings/plans'));
const SettingsOrganisations = lazy(() => import('../pages/settings/organisations'));
const SettingsOrganisation = lazy(() => import('../pages/settings/organisation'));
const OrganisationLogin = lazy(() => import('../pages/organisation-login'));
const Invite = lazy(() => import('../pages/invite'));
const BillingTest = lazy(() => import('../pages/change-plan'));

const routes: PreRoute[] = [
	{
		path: '/change-email',
		element: <ChangeEmail />,
	},
	{
		path: '/invite',
		element: <ConversionLayout />,
		children: [
			{
				index: true,
				element: <Invite />,
			},
		],
	},
	{
		path: '/login',
		element: <ConversionLayout />,
		children: [
			{
				index: true,
				element: <Login />,
			},
		],
	},
	{
		path: '/login/social',
		element: <ConversionLayout />,
		children: [
			{
				index: true,
				element: <LoginGoogle />,
			},
		],
	},
	{
		path: '/logout',
		element: <ConversionLayout />,
		children: [
			{
				index: true,
				element: <Logout />,
			},
		],
	},
	{
		path: '/forgot-password',
		element: <ConversionLayout />,
		children: [
			{
				index: true,
				element: <ResetPassword />,
			},
		],
	},
	{
		path: '/forgot-password-confirm',
		element: <ConversionLayout />,
		children: [
			{
				index: true,
				element: <ConfirmPassword />,
			},
		],
	},
	{
		element: <Private />,
		children: [
			{
				path: '/organisation-login',
				element: <ConversionLayout />,
				children: [
					{
						index: true,
						element: <OrganisationLogin />,
					},
				],
			},
			{
				path: '/cameras/:id',
				element: <CameraLayout />,
				children: [
					{
						index: true,
						element: <Camera />,
					},
					{
						path: '/cameras/:id/room',
						element: null,
					},
					{
						path: '/cameras/:id/cameras',
						element: null,
					},
					{
						path: '/cameras/:id/analytics',
						element: <CameraAnalytics />,
					},
				],
			},
			{
				path: '/kiosks/:id',
				element: <KioskLayout />,
				children: [
					{
						index: true,
						element: <Kiosk />,
					},
					{
						path: '/kiosks/:id/analytics',
						children: [
							{
								index: true,
								element: <KioskAnalytics />,
							},
							{
								path: '/kiosks/:id/analytics/sessions/:sessionId',
								element: <KioskSession />,
							},
						],
					},
				],
			},
			{
				path: '/campaigns/:id',
				element: <CampaignLayout />,
				children: [
					{
						index: true,
						element: <Campaign />,
					},
					{
						path: '/campaigns/:id/editor',
						element: <CampaignEditor />,
					},
				],
			},
			{
				path: '/locations/:id',
				element: <LocationLayout />,
				children: [
					{
						index: true,
						element: <Location />,
					},
					{
						path: '/locations/:id/room',
						element: null,
					},
					{
						path: '/locations/:id/cameras',
						element: <LocationCameras />,
					},
					{
						path: '/locations/:id/analytics',
						element: <LocationAnalytics />,
					},
				],
			},
			{
				path: '/settings',
				element: <SettingsLayout />,
				children: [
					{
						index: true,
						element: <SettingsOverview />,
					},
					{
						path: '/settings/profile',
						element: <SettingsOverview />,
					},
					{
						path: '/settings/security',
						element: <SettingsSecurity />,
					},
					{
						path: '/settings/organisations',
						children: [
							{
								index: true,
								element: <SettingsOrganisations />,
							},
							{
								path: '/settings/organisations/:id',
								element: <SettingsOrganisation />,
							},
						],
					},
					{
						path: '/settings/billing',
						element: <SettingsBilling />,
					},
					{
						path: '/settings/plan',
						element: <SettingsPlans />,
					},

					{
						path: '/settings/change-plan',
						element: <BillingTest />,
					},
				],
			},
			{
				path: '/',
				element: <CampaignsLayout />,
				children: [
					{
						path: '/campaigns',
						children: [
							{
								index: true,
								element: <Campaigns />,
							},
						],
					},
				],
			},
			{
				path: '/',
				element: <Layout />,
				children: [
					{
						index: true,
						element: <Home />,
					},
					{
						path: '/admin',
						element: <Protected permissionSets={[['admin']]} />,
						children: [
							{
								index: true,
								element: <div>Admin</div>,
							},
						],
					},
					{
						path: '/support',
						element: <SettingsSupport />,
					},
					{
						path: '/locations',
						children: [
							{
								index: true,
								element: <Locations />,
							},
							{
								path: '/locations/:id',
								element: <Location />,
							},
						],
					},
					{
						path: '/cameras',
						children: [
							{
								index: true,
								element: <Cameras />,
							},
							{
								path: '/cameras/:id',
							},
						],
					},
					{
						path: '/kiosks',
						children: [
							{
								index: true,
								element: <Kiosks />,
							},
						],
					},
					{
						path: '/campaigns',
						children: [
							{
								index: true,
								element: <Campaigns />,
							},
						],
					},
					{
						path: '/offline',
						element: <Offline />,
					},
				],
			},
		],
	},
];

export default routes;
