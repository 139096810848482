import * as React from 'react';
import { useState } from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import Sheet from '@mui/joy/Sheet';
import { Link, useLocation } from 'react-router-dom';
import { Box, ListItemContent, ListItemDecorator, Tooltip, Typography } from '@mui/joy';
import { SidebarContext, useRoutes, useSidebar } from '~/utils/contexts';

interface ISidebarItemProps {
	disabled?: boolean;
	disableActive?: boolean;
	root?: string;
	name: string;
	path: string;
	icon: React.ReactNode;
}

interface ISidebarItemsProps {
	bottom?: true;
}

export const SidebarItems: React.FC<React.PropsWithChildren<ISidebarItemsProps>> = (props) => {
	return (
		<List
			size="sm"
			sx={{
				'--ListItem-radius': '6px',
				'--List-gap': '6px',
				py: 2,
				px: 1.625,
				width: '100%',
				mt: props.bottom ? 'auto' : undefined,
				flexGrow: props.bottom ? 0 : undefined,
			}}
		>
			{props.children}
		</List>
	);
};

export const SidebarItem: React.FC<ISidebarItemProps> = (props) => {
	const currentRouteItem = useLocation();
	const { onMobileToggle } = useSidebar();

	const { disabled = false } = props;
	const { root = '/' } = props;

	const isActive =
		!props.disableActive &&
		(props.path === root
			? currentRouteItem?.pathname === props.path
			: currentRouteItem?.pathname?.includes(props.path));

	return (
		<ListItem
			sx={{
				display: disabled ? 'none' : 'flex',
				paddingInlineStart: 0,
				paddingInlineEnd: 0,
				paddingBlockStart: 0,
				paddingBlockEnd: 0,
			}}
		>
			<ListItemButton
				// selected={isActive}
				variant={isActive ? 'soft' : 'plain'}
				onClick={() => onMobileToggle?.(false)}
				component={disabled ? Typography : Link}
				to={props.path!}
				sx={{
					opacity: 1,
					cursor: 'pointer',
					display: 'flex',
					justifyContent: 'center',
					minInlineSize: 'none',
					marginInlineEnd: 'none',
					paddingBlock: 0,
					marginInline: 0,
					marginBlock: 0,
					paddingInlineStart: 0,
					paddingInlineEnd: 0,
				}}
			>
				<ListItemDecorator
					sx={{
						fontSize: '1.125rem',
						width: 39,
						height: 39,
						margin: 0,
						justifyContent: 'center',
						svg: { color: isActive ? 'text.primary' : 'text.tertiary' },
					}}
				>
					{props.icon}
				</ListItemDecorator>

				<ListItemContent
					sx={{
						minHeight: 39,
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<Typography
						level="title-sm"
						fontWeight="500"
						textColor={isActive ? 'text.primary' : 'text.tertiary'}
					>
						{props.name}
					</Typography>
				</ListItemContent>
			</ListItemButton>
		</ListItem>
	);
};

export const SidebarProvider: React.FC<React.PropsWithChildren> = (props) => {
	const [mobileOpen, setMobileOpen] = useState(false);

	const openSidebar = () => {
		if (typeof document !== 'undefined') {
			document.body.style.overflow = 'hidden';
			document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
		}
	};

	const closeSidebar = () => {
		if (typeof document !== 'undefined') {
			document.documentElement.style.removeProperty('--SideNavigation-slideIn');
			document.body.style.removeProperty('overflow');
		}
	};

	const toggleSidebar = () => {
		if (typeof window !== 'undefined' && typeof document !== 'undefined') {
			const slideIn = window
				.getComputedStyle(document.documentElement)
				.getPropertyValue('--SideNavigation-slideIn');

			if (slideIn) {
				closeSidebar();
			} else {
				openSidebar();
			}
		}
	};

	const handleMobileToggle = (isOpen?: boolean) => {
		const isToggle = typeof isOpen === 'undefined';

		if (isToggle) {
			toggleSidebar();
		} else if (isOpen) {
			openSidebar();
		} else {
			closeSidebar();
		}

		setMobileOpen(!isToggle ? isOpen : !mobileOpen);
	};

	return (
		<SidebarContext.Provider
			value={{
				mobileOpen,
				onMobileToggle: handleMobileToggle,
			}}
		>
			{props.children}
		</SidebarContext.Provider>
	);
};

const Sidebar: React.FC<React.PropsWithChildren> = (props) => {
	const { onMobileToggle } = useSidebar();

	return (
		<>
			<Box
				sx={{
					position: 'fixed',
					zIndex: 2,
					top: 0,
					left: `calc(var(--Sidebar-width, 250px) * -1)`,
					width: '100vw',
					height: '100vh',
					opacity: 'var(--SideNavigation-slideIn, 0)',
					backgroundColor: 'var(--merlin-palette-background-backdrop)',
					transition: 'opacity 0.4s',
					transform: {
						xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
						lg: 'translateX(-100%)',
					},
				}}
				onClick={() => onMobileToggle?.(false)}
			/>

			<Sheet
				color="neutral"
				sx={(theme) => ({
					'--Sheet-background': theme.vars.palette.background.body,
					backgroundColor: 'var(--Sheet-background)',
					position: 'sticky',
					transform: {
						xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
						md: 'none',
					},
					transition: 'transform 0.4s',
					zIndex: 1300,
					width: 'var(--Sidebar-width)',
					top: 0,
					flexShrink: 0,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-start',
					gap: 1,
					borderRight: '1px solid',
					borderColor: 'divider',
					overflow: 'auto',
					borderRadius: 0,
				})}
			>
				<GlobalStyles
					styles={{
						':root': {
							'--Sidebar-width': '250px',
						},
					}}
				/>
				{props.children}
			</Sheet>
		</>
	);
};

export default Sidebar;
