import { CssVarsThemeOptions, extendTheme, PaletteRange } from '@mui/joy/styles';

// This part could be declared in your theme file
declare module '@mui/joy/Drawer' {
	interface DrawerPropsSizeOverrides {
		xl: true;
	}
}

// You can put this to any file that's included in your tsconfig
declare module '@mui/joy/styles' {
	interface TypographySystemOverrides {
		'title-xs': true;
		subtitle: true;
	}
}

declare module '@mui/joy/IconButton' {
	interface IconButtonPropsSizeOverrides {
		xl: true;
	}
}

declare module '@mui/joy/styles' {
	interface BreakpointOverrides {
		xxl: true;
	}
}

declare module '@mui/joy/styles' {
	interface ColorPalettePropOverrides {
		secondary: true;
		info: true;
	}

	interface Palette {
		secondary: PaletteRange;
		info: PaletteRange;
	}
}

const FONT_FAMILY_DISPLAY = ['neulis-neue', 'sans-serif'].join(', ');
const FONT_FAMILY_BODY = ['neulis-sans', 'sans-serif'].join(', ');

const _breakpoints = {
	values: {
		xs: 0,
		sm: 600,
		md: 900,
		lg: 1200,
		xl: 1500,
		xxl: 1800,
	},
};

const _theme = extendTheme({
	breakpoints: _breakpoints,
});

const { breakpoints } = _theme;

export const theme = extendTheme({
	cssVarPrefix: 'merlin',
	components: {
		JoyList: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					...(ownerState.variant === 'outlined' && {
						border: '1px solid',
						borderRadius: theme.spacing(0.5),
						borderColor: theme.palette.divider,
					}),
				}),
			},
		},
		JoyListItemButton: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					...(ownerState.color === 'neutral' && {
						'&.Mui-selected': {
							backgroundColor: theme.vars.palette.neutral.solidBg,
						},
					}),
				}),
			},
		},
		JoyDialogTitle: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					color: theme.vars.palette.text.primary,
				}),
			},
		},
		JoyDialogContent: {
			styleOverrides: {
				root: () => ({
					[theme.breakpoints.up('xs')]: {
						gap: theme.spacing(1),
					},
					[theme.breakpoints.up('md')]: {
						gap: theme.spacing(2),
					},
				}),
			},
		},
		JoySwitch: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({}),
			},
		},
		JoyDrawer: {
			styleOverrides: {
				root: ({ ownerState, theme }: any) => ({
					...(ownerState.size === 'xl' &&
						(ownerState.anchor === 'top' || ownerState.anchor === 'bottom') && {
							'--Drawer-verticalSize': 'clamp(450px, 90%, 100%)',
						}),

					...(ownerState.size === 'xl' &&
						(ownerState.anchor === 'left' || ownerState.anchor === 'right') && {
							'--Drawer-horizontalSize': 'clamp(500px, 90%, 100%)',
						}),

					...(ownerState.size === 'md' && {
						'--Drawer-horizontalSize': 'clamp(500px, 30%, 100%)',
					}),
				}),
			},
		},
		JoyLinearProgress: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					...(ownerState.color === 'success' && {
						backgroundColor: theme.vars.palette.divider,
						color: 'var(--merlin-palette-success-500, #30D98A)',
					}),
				}),
			},
		},
		JoyCard: {
			styleOverrides: {
				root: {
					// border: 'none',
					background: 'var(--merlin-palette-background-level1, #30D98A)',
				},
			},
		},

		JoyCheckbox: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					...(ownerState.variant === 'outlined' &&
						{
							// border: `2px solid ${theme.vars.palette.neutral.outlinedBorder}`,
						}),
				}),
			},
		},
		JoyTab: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					padding: '8px 32px',
					borderRadius: 4,
					'&::after': {
						height: 0,
					},
					color: theme.vars.palette.text.secondary,
					fontWeight: 500,

					'&.Mui-selected': {
						backgroundColor: `${theme.vars.palette.background.level2} !important`,
						color: theme.vars.palette.text.primary,
					},
				}),
			},
		},
		JoyTabList: {
			defaultProps: {
				disableUnderline: true,
			},
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					width: 'fit-content',
					gap: 4,
				}),
			},
		},
		JoyTabs: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					backgroundColor: `${theme.vars.palette.background.level1} !important`,

					'.MuiTabList-root': {
						padding: '4px !important',
						borderRadius: 4,

						border: `1px solid ${theme.palette.divider}`,
					},
				}),
			},
		},
		JoyIconButton: {
			defaultProps: {},
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					...(ownerState.size === 'xl' && {
						'--IconButton-fontSizeXl': '1.5rem',
						fontSize: 'var(--IconButton-fontSizeXl)',
					}),
					...(ownerState.size === 'md' && {
						svg: {
							fontSize: '1rem',
						},
					}),
					padding: 0,
				}),
			},
		},
		JoyAutocomplete: {
			defaultProps: {
				variant: 'soft',
				color: 'neutral',
			},
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					...(ownerState.variant === 'soft' && {
						border: `1px solid ${theme.vars.palette.neutral[600]}`,
						boxShadow: 'none',
					}),
				}),
			},
		},

		JoyAlert: {
			styleOverrides: {
				defaultProps: {
					variant: 'soft',
				},
				root: ({ ownerState, theme }) => ({
					...(ownerState.variant === 'soft' &&
						ownerState.color === 'danger' && {
							border: `1px solid ${theme.vars.palette.danger.outlinedBorder}`,
						}),
				}),
			},
		},
		JoyInput: {
			defaultProps: {
				variant: 'soft',
				color: 'neutral',
			},
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					...(ownerState.variant === 'soft' && {
						border: `1px solid ${theme.vars.palette.neutral[600]}`,
						boxShadow: 'none',
					}),
				}),
			},
		},
		JoyButton: {
			defaultProps: {
				color: 'neutral',
			},
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					...(ownerState.variant === 'outlined' &&
						ownerState.color === 'danger' && {
							backgroundColor: theme.vars.palette.danger.softBg,
						}),
					...(ownerState.variant === 'outlined' &&
						ownerState.color === 'success' && {
							backgroundColor: theme.vars.palette.success.softBg,
						}),

					...(ownerState.loading &&
						ownerState.color === 'primary' && {
							'.MuiCircularProgress-root': {
								'--CircularProgress-progressColor':
									'var(--merlin-palette-primary-50, #8517FF)',
							},
						}),
					...(ownerState.loading &&
						ownerState.color === 'success' && {
							'.MuiCircularProgress-root': {
								'--CircularProgress-trackColor':
									'var(--merlin-palette-success-300, #8517FF)',
								'--CircularProgress-progressColor':
									'var(--merlin-palette-success-50, #8517FF)',
							},
						}),
					...(ownerState.loading &&
						ownerState.color === 'danger' && {
							'.MuiCircularProgress-root': {
								'--CircularProgress-trackColor':
									'var(--merlin-palette-danger-300, #8517FF)',
								'--CircularProgress-progressColor':
									'var(--merlin-palette-danger-50, #8517FF)',
							},
						}),
				}),
			},
		},
		JoyDivider: {
			defaultProps: {
				variant: 'soft',
			},
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					backgroundColor: theme.vars.palette.divider,
				}),
			},
		},
		JoyFormControl: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					'&.Mui-error': {
						'--FormHelperText-color': theme.vars.palette.danger['600'],
					},
				}),
			},
		},
		JoySelect: {
			styleOverrides: {
				defaultProps: {
					variant: 'soft',
					color: 'neutral',
				},

				root: ({ ownerState, theme }) => ({
					...(ownerState.variant === 'soft' && {
						border: `1px solid ${theme.vars.palette.neutral[600]}`,
						boxShadow: 'none',
					}),
					padding: theme.spacing(0.5, 2),

					...(ownerState.variant === 'solid' && {
						padding: theme.spacing(1.5, 2),
						border: '1px solid',
						borderColor: theme.palette.divider,
						boxShadow: `0px 2px 0px 0px ${theme.palette.divider}`,
					}),
				}),
			},
		},
		JoyAvatar: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					...(ownerState.variant === 'solid' &&
						ownerState.color === 'secondary' && {
							backgroundColor: theme.vars.palette.secondary['700'],
						}),
				}),
			},
		},
		JoyListSubheader: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					fontSize: '1rem',
					fontWeight: 500,
					fontFamily: FONT_FAMILY_BODY,
					letterSpacing: 0,
					textTransform: 'none',
					color: 'var(--merlin-palette-neutral-800, #262230)',
				}),
			},
		},
		JoySvgIcon: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					fontSize: '1rem',
					...(ownerState.size === 'sm' && {
						width: theme.spacing(2),
						height: theme.spacing(2),
					}),
				}),
			},
		},

		JoySheet: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					borderRadius: theme.spacing(1),
				}),
			},
		},
	},
	typography: {
		h1: {
			fontWeight: 500,
			fontSize: '1.625rem',
			[breakpoints.up('xs')]: {
				fontSize: '1.375rem',
			},
			[breakpoints.up('md')]: {
				fontSize: '1.5rem',
			},
			[breakpoints.up('lg')]: {
				fontSize: '1.625rem',
			},
		},
		h2: {
			fontWeight: 500,
			fontSize: '1.375rem',

			[breakpoints.up('xs')]: {
				fontSize: '1.125rem',
			},
			[breakpoints.up('md')]: {
				fontSize: '1.25rem',
			},
			[breakpoints.up('lg')]: {
				fontSize: '1.375rem',
			},
		},
		h3: {
			fontWeight: 500,
			fontSize: '1.25rem',
			[breakpoints.up('xs')]: {
				fontSize: '1.125rem',
			},
			[breakpoints.up('lg')]: {
				fontSize: '1.25rem',
			},
		},
		h4: {
			fontWeight: 500,
		},
		'title-lg': {
			fontWeight: 500,
			// fontFamily: FONT_FAMILY_DISPLAY,
		},
		'title-md': {
			fontWeight: 500,
			// fontFamily: FONT_FAMILY_DISPLAY,
		},
		'title-sm': {
			fontWeight: 500,
			// fontFamily: FONT_FAMILY_DISPLAY,
		},
		'title-xs': {
			fontSize: '0.75rem',
			fontWeight: 500,
			color: 'var(--merlin-palette-neutral-600, #6D01D2)',
		},
		'body-xs': {
			color: 'var(--merlin-palette-neutral-600, #6D01D2)',
		},
		subtitle: {
			fontSize: '0.75rem',
			fontWeight: 600,
			fontFamily: FONT_FAMILY_DISPLAY,
			textTransform: 'uppercase',
			letterSpacing: '0.1rem',
			color: 'var(--merlin-palette-neutral-800, #262230)',
		},
	},
	breakpoints: _breakpoints,
	fontFamily: {
		display: FONT_FAMILY_BODY,
		body: FONT_FAMILY_BODY,
	},
	colorSchemes: {
		light: {
			palette: {
				divider: 'var(--merlin-palette-neutral-300, #EBE9F0)',
				text: {
					primary: 'var(--merlin-palette-neutral-900, #09080D)',
					secondary: 'var(--merlin-palette-neutral-700, #5500A6)',
					tertiary: 'var(--merlin-palette-neutral-600, #6D01D2)',
					icon: 'var(--merlin-palette-neutral-600, #6D01D2)',
				},
				background: {
					body: 'var(--merlin-palette-neutral-50, #EBE9F0)',
					level3: 'var(--merlin-palette-neutral-300, #EBE9F0)',
					level2: 'var(--merlin-palette-neutral-200, #F3F1F6)',
					level1: 'var(--merlin-palette-neutral-100, #F3F1F6)',
				},
				primary: {
					50: '#F7F0FF',
					100: '#E9D6FF',
					200: '#C08AFF',
					300: '#A85CFF',
					400: '#8F2EFF',
					500: '#8517FF',
					600: '#6D01D2',
					700: '#5500A6',
					800: '#3D0079',
					900: '#26004D',
					softBg: 'var(--merlin-palette-primary-100, #E9D6FF)',
					softColor: 'var(--merlin-palette-primary-500, #8517FF)',
					softHoverColor: 'var(--merlin-palette-primary-500, #8517FF)',
					softHoverBg: 'var(--merlin-palette-primary-200, #C08AFF)',
					softActiveColor: 'var(--merlin-palette-primary-600, #6D01D2)',
					softActiveBg: 'var(--merlin-palette-primary-200, #C08AFF)',
				},
				secondary: {
					50: '#F2E1F2',
					100: '#DDBCE0',
					200: '#BA7EBF',
					300: '#903E94',
					400: '#823E88',
					500: '#5F0966',
					600: '#54015B',
					700: '#46004C',
					800: '#36003B',
					900: '#240028',

					solidBg: 'var(--merlin-palette-secondary-400, #823E88)',
					solidActiveBg: 'var(--merlin-palette-secondary-500, #5F0966)',

					outlinedBorder: 'var(--merlin-palette-secondary-100, #5F0966)',
					outlinedColor: 'var(--merlin-palette-secondary-100, #46004C)',
					outlinedActiveBg: 'var(--merlin-palette-secondary-100, #DDBCE0)',

					softColor: 'var(--merlin-palette-secondary-300, #903E94)',
					softHoverColor: 'var(--merlin-palette-secondary-300, #903E94)',
					softActiveColor: 'var(--merlin-palette-secondary-400, #54015B)',

					softBg: 'var(--merlin-palette-secondary-50, #F2E1F2)',
					softHoverBg: 'var(--merlin-palette-secondary-100, #BA7EBF)',
					softActiveBg: 'var(--merlin-palette-secondary-200, #BA7EBF)',

					plainColor: 'var(--merlin-palette-secondary-700, #46004C)',
					plainActiveBg: 'var(--merlin-palette-secondary-100, #DDBCE0)',
				},
				neutral: {
					50: '#FDFAFF',
					100: '#F3F1F6',
					200: '#EBE9F0',
					300: '#DFD8E6',
					400: '#C3BACF',
					500: '#A095AD',
					600: '#776B85',
					700: '#5A4F69',
					800: '#262230',
					900: '#09080D',

					softBg: 'var(--merlin-palette-neutral-100, #EBE9F0)',
					softColor: 'var(--merlin-palette-neutral-600, #776B85)',
					softHoverColor: 'var(--merlin-palette-neutral-600, #776B85)',
					softHoverBg: 'var(--merlin-palette-neutral-300, #DFD8E6)',
					softActiveColor: 'var(--merlin-palette-neutral-700, #5A4F69)',
					softActiveBg: 'var(--merlin-palette-neutral-400, #EBE9F0)',

					solidColor: 'var(--merlin-palette-neutral-100, #F3F1F6)',
					solidBg: 'var(--merlin-palette-neutral-500, #A095AD)',
					solidHoverBg: 'var(--merlin-palette-neutral-600, #776B85)',
					solidActiveBg: 'var(--merlin-palette-neutral-400, #C3BACF)',
				},
				common: {
					white: '#FFFFFF',
					black: '#000000',
				},
				success: {
					50: '#DFFFF0',
					100: '#B8FCDC',
					200: '#92F6C8',
					300: '#70F0B2',
					400: '#4DE69F',
					500: '#30D98A',
					600: '#23B570',
					700: '#138D55',
					800: '#0D633A',
					900: '#04361F',

					softColor: 'var(--merlin-palette-success-800, #0D633A)',
					softActiveColor: 'var(--merlin-palette-success-900, #04361F)',
					softBg: 'var(--merlin-palette-success-50, #DFFFF0)',
					softHoverBg: 'var(--merlin-palette-success-100, #B8FCDC)',
					softActiveBg: 'var(--merlin-palette-success-200, #92F6C8)',

					solidColor: 'var(--merlin-palette-success-900, #04361F)',
					solidActiveColor: 'var(--merlin-palette-success-900, #04361F)',
					solidBg: 'var(--merlin-palette-success-500, #30D98A)',
					solidHoverBg: 'var(--merlin-palette-success-600, #23B570)',
					solidActiveBg: 'var(--merlin-palette-success-700, #138D55)',
				},
				info: {
					50: '#E0F3FC',
					100: '#B3E1FA',
					200: '#80CDF7',
					300: '#4DB9F4',
					400: '#1AA4F1',
					500: '#0791E7',
					600: '#067AC4',
					700: '#0562A0',
					800: '#04497C',
					900: '#023051',
				},

				danger: {
					50: '#FEE4EA',
					100: '#FBC1CE',
					200: '#F99EB3',
					300: '#F57B98',
					400: '#F1597D',
					500: '#ED3862',
					600: '#C12B4E',
					700: '#941E3B',
					800: '#661427',
					900: '#300810',

					softColor: 'var(--merlin-palette-danger-600, #C12B4E)',
					softActiveColor: 'var(--merlin-palette-danger-700, #941E3B)',
					softBg: 'var(--merlin-palette-danger-50, #FEE4EA)',
					softHoverBg: 'var(--merlin-palette-danger-100, #FBC1CE)',
					softActiveBg: 'var(--merlin-palette-danger-200, #F99EB3)',

					solidColor: 'var(--merlin-palette-danger-50, #FEE4EA)',
					solidActiveColor: 'var(--merlin-palette-danger-100, #FBC1CE)',
					solidBg: 'var(--merlin-palette-danger-500, #ED3862)',
					solidHoverBg: 'var(--merlin-palette-danger-600, #C12B4E)',
					solidActiveBg: 'var(--merlin-palette-danger-700, #941E3B)',
				},
			},
		},
		dark: {
			palette: {
				divider: 'var(--merlin-palette-neutral-600, #393141)',
				text: {
					primary: 'var(--merlin-palette-neutral-100, #F1EDF2)',
					secondary: 'var(--merlin-palette-neutral-200, #C3BACF)',
					tertiary: 'var(--merlin-palette-neutral-300, #A095AD)',
					icon: 'var(--merlin-palette-neutral-100, #776B84)',
				},
				background: {
					level2: 'var(--merlin-palette-neutral-600, #14121A)',
					level1: 'var(--merlin-palette-neutral-700, #14121A)',
				},
				primary: {
					50: '#F7F0FF',
					100: '#E9D6FF',
					200: '#C08AFF',
					300: '#A85CFF',
					400: '#8F2EFF',
					500: '#8517FF',
					600: '#6D01D2',
					700: '#5500A6',
					800: '#3D0079',
					900: '#26004D',
					solidBg: 'var(--merlin-palette-primary-800, #8F2EFF)',
					softBg: 'var(--merlin-palette-primary-100, #E9D6FF)',
					softColor: 'var(--merlin-palette-primary-500, #8517FF)',
					softHoverColor: 'var(--merlin-palette-primary-500, #8517FF)',
					softHoverBg: 'var(--merlin-palette-primary-200, #C08AFF)',
					softActiveColor: 'var(--merlin-palette-primary-600, #6D01D2)',
					softActiveBg: 'var(--merlin-palette-primary-200, #C08AFF)',
				},
				secondary: {
					50: '#F2E1F2',
					100: '#DDBCE0',
					200: '#BA7EBF',
					300: '#903E94',
					400: '#823E88',
					500: '#5F0966',
					600: '#54015B',
					700: '#46004C',
					800: '#36003B',
					900: '#240028',

					solidBg: 'var(--merlin-palette-secondary-400, #823E88)',
					solidActiveBg: 'var(--merlin-palette-secondary-500, #5F0966)',
					solidBorder: 'var(--merlin-palette-secondary-500, #5F0966)',
					solidColor: 'var(--merlin-palette-secondary-700, #46004C)',
					solidActiveColor: 'var(--merlin-palette-secondary-100, #DDBCE0)',

					softBg: 'var(--merlin-palette-secondary-100, #DDBCE0)',
					softColor: 'var(--merlin-palette-secondary-500, #5F0966)',
					softHoverColor: 'var(--merlin-palette-secondary-500, #5F0966)',
					softHoverBg: 'var(--merlin-palette-secondary-200, #BA7EBF)',
					softActiveColor: 'var(--merlin-palette-secondary-600, #54015B)',
					softActiveBg: 'var(--merlin-palette-secondary-200, #BA7EBF)',

					outlinedBg: 'var(--merlin-palette-secondary-100, #DDBCE0)',
					outlinedColor: 'var(--merlin-palette-secondary-500, #5F0966)',
					outlinedHoverColor: 'var(--merlin-palette-secondary-500, #5F0966)',
					outlinedHoverBg: 'var(--merlin-palette-secondary-200, #BA7EBF)',
					outlinedActiveColor: 'var(--merlin-palette-secondary-600, #54015B)',
					outlinedActiveBg: 'var(--merlin-palette-secondary-200, #BA7EBF)',

					plainColor: 'var(--merlin-palette-secondary-700, #46004C)',
					plainActiveBg: 'var(--merlin-palette-secondary-100, #DDBCE0)',
				},

				neutral: {
					50: '#FDFAFF',
					100: '#F3F1F6',
					200: '#C3BACF',
					300: '#A095AD',
					400: '#776B84',
					500: '#5A4F69',
					600: '#393042',
					700: '#262230',
					800: '#14121A',
					900: '#0E080F',
					plainColor: 'var(--merlin-palette-neutral-100, #F3F1F6)',
					softColor: 'var(--merlin-palette-neutral-100, #F3F1F6)',
					softHoverColor: 'var(--merlin-palette-neutral-50, #FDFAFF)',
					softActiveColor: 'var(--merlin-palette-neutral-50, #FDFAFF)',
					softDisabledColor: 'var(--merlin-palette-neutral-200, #FDFAFF)',
					softBg: 'var(--merlin-palette-neutral-700, #14121A)',
					softHoverBg: 'var(--merlin-palette-neutral-800, #262230)',
					softActiveBg: 'var(--merlin-palette-neutral-600, #393042)',
					softDisabledBg: 'var(--merlin-palette-neutral-800, #14121A)',
					softBorder: 'var(--merlin-palette-neutral-600, #393042)',

					outlinedColor: 'var(--merlin-palette-neutral-100, #F3F1F6)',
					outlinedHoverColor: 'var(--merlin-palette-neutral-50, #FDFAFF)',
					outlinedActiveColor: 'var(--merlin-palette-neutral-50, #FDFAFF)',
					outlinedDisabledColor: 'var(--merlin-palette-neutral-200, #FDFAFF)',
					outlinedBg: 'var(--merlin-palette-neutral-800, #14121A)',
					outlinedHoverBg: 'var(--merlin-palette-neutral-700, #A095AD)',
					outlinedActiveBg: 'var(--merlin-palette-neutral-600, #262230)',
					outlinedDisabledBg: 'var(--merlin-palette-neutral-800, #14121A)',
					outlinedBorder: 'var(--merlin-palette-neutral-600, #393042)',
					outlinedDisabledBorder: 'var(--merlin-palette-neutral-700, #262230)',

					solidColor: 'var(--merlin-palette-neutral-100, #0E080F)',
					solidHoverColor: 'var(--merlin-palette-neutral-50, #FDFAFF)',
					solidActiveColor: 'var(--merlin-palette-neutral-50, #FDFAFF)',
					solidDisabledColor: 'var(--merlin-palette-neutral-200, #FDFAFF)',
					solidBg: 'var(--merlin-palette-neutral-800, #262230)',
					solidHoverBg: 'var(--merlin-palette-neutral-600, #0E080F)',
					solidActiveBg: 'var(--merlin-palette-neutral-500, #393042)',
					solidDisabledBg: 'var(--merlin-palette-neutral-700, #262230)',
				},
				common: {
					white: '#F1EDF2',
					black: '#0E080F',
				},
				success: {
					50: '#DFFFF0',
					100: '#C0F5D2',
					200: '#92F6C8',
					300: '#70F0B2',
					400: '#4DE69F',
					500: '#30D98A',
					600: '#24B052',
					700: '#138D55',
					800: '#105A28',
					900: '#072E14',

					softColor: 'var(--merlin-palette-success-100, #B8FCDC)',
					softActiveColor: 'var(--merlin-palette-success-50, #DFFFF0)',
					softDisabledColor: 'var(--merlin-palette-success-200, #92F6C8)',
					softBg: 'var(--merlin-palette-success-900, #072E14)',
					softHoverBg: 'var(--merlin-palette-success-800, #105A28)',
					softActiveBg: 'var(--merlin-palette-success-700, #138D55)',
					softDisabledBg: 'var(--merlin-palette-success-900, #072E14)',

					outlinedColor: 'var(--merlin-palette-success-100, #B8FCDC)',
					outlinedActiveBorder: 'var(--merlin-palette-success-200, #DFFFF0)',
					outlinedActiveColor: 'var(--merlin-palette-success-50, #DFFFF0)',
					outlinedDisabledColor: 'var(--merlin-palette-success-200, #92F6C8)',
					outlinedBg: 'var(--merlin-palette-success-900, #072E14)',
					outlinedHoverBg: 'var(--merlin-palette-success-800, #105A28)',
					outlinedActiveBg: 'var(--merlin-palette-success-700, #138D55)',
					outlinedDisabledBg: 'var(--merlin-palette-success-900, #072E14)',
					outlinedBorder: 'var(--merlin-palette-success-600, #105A28)',
					outlinedDisabledBorder: 'var(--merlin-palette-success-900, #072E14)',

					solidColor: 'var(--merlin-palette-common-white, #F1EDF2)',
					solidActiveColor: 'var(--merlin-palette-success-50, #DFFFF0)',
					solidDisabledColor: 'var(--merlin-palette-success-200, #92F6C8)',
					solidBg: 'var(--merlin-palette-success-800, #105A28)',
					solidHoverBg: 'var(--merlin-palette-success-700, #138D55)',
					solidActiveBg: 'var(--merlin-palette-success-600, #23B570)',
					solidDisabledBg: 'var(--merlin-palette-success-800, #105A28)',
				},
				danger: {
					50: '#FEE4EA',
					100: '#FBC1CE',
					200: '#F99EB3',
					300: '#F57B98',
					400: '#F1597D',
					500: '#ED3862',
					600: '#941E3B',
					700: '#69162A',
					800: '#762D3F',
					900: '#320912',

					softColor: 'var(--merlin-palette-danger-100, #FBC1CE)',
					softActiveColor: 'var(--merlin-palette-danger-50, #FEE4EA)',
					softDisabledColor: 'var(--merlin-palette-danger-200, #F99EB3)',
					softBg: 'var(--merlin-palette-danger-900, #320912)',
					softHoverBg: 'var(--merlin-palette-danger-800, #661427)',
					softActiveBg: 'var(--merlin-palette-danger-700, #941E3B)',
					softDisabledBg: 'var(--merlin-palette-danger-900, #300810)',

					outlinedColor: 'var(--merlin-palette-danger-100, #FBC1CE)',
					outlinedActiveColor: 'var(--merlin-palette-danger-50, #FEE4EA)',
					outlinedDisabledColor: 'var(--merlin-palette-danger-200, #F99EB3)',
					outlinedBg: 'var(--merlin-palette-danger-900, #300810)',
					outlinedHoverBg: 'var(--merlin-palette-danger-800, #661427)',
					outlinedActiveBg: 'var(--merlin-palette-danger-700, #941E3B)',
					outlinedDisabledBg: 'var(--merlin-palette-danger-900, #300810)',
					outlinedBorder: 'var(--merlin-palette-danger-800, #300810)',
					outlinedDisabledBorder: 'var(--merlin-palette-danger-900, #300810)',

					solidColor: 'var(--merlin-palette-danger-50, #FEE4EA)',
					solidActiveColor: 'var(--merlin-palette-danger-100, #FBC1CE)',
					solidDisabledColor: 'var(--merlin-palette-danger-200, #F99EB3)',
					solidBg: 'var(--merlin-palette-danger-700, #661427)',
					solidHoverBg: 'var(--merlin-palette-danger-700, #941E3B)',
					solidActiveBg: 'var(--merlin-palette-danger-600, #C12B4E)',
					solidDisabledBg: 'var(--merlin-palette-danger-800, #661427)',
				},
				info: {
					50: '#E0F3FC',
					100: '#B3E1FA',
					200: '#80CDF7',
					300: '#4DB9F4',
					400: '#1AA4F1',
					500: '#0791E7',
					600: '#067AC4',
					700: '#0562A0',
					800: '#04497C',
					900: '#023051',
				},
				background: {
					body: 'var(--merlin-palette-neutral-900, #0E080F)',
					surface: 'var(--merlin-palette-neutral-800, #262230)',
				},
			},
		},
	},
} as CssVarsThemeOptions);

// #0E080F
