import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Stack } from '@mui/joy';
import Box from '@mui/joy/Box';
import { useRef } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import Header, { HeaderCrumb, HeaderCrumbs } from '~/components/Layout/Header';
import Sidebar, { SidebarItem, SidebarItems } from '~/components/Layout/Sidebar';
import { useAuth } from '~/hooks/auth';
import { ICamera, ICamerasResponse } from '~/models/api/camera';
import { useGet } from '~/utils/api';
import api from '~/utils/api/endpoints';
import { LayoutContext } from '~/utils/contexts';

const CameraLayout = () => {
	const { organisation } = useAuth();
	const { id: cameraId } = useParams();
	const $content = useRef(null);
	const contentId = 'merlin-cloud-app-content';

	const {
		data: camera,
		isLoading,
		error,
	} = useGet<ICamera>(api.camera, {
		params: { cameraId },
	});

	const { data: cameras, isLoading: isLoadingCameras } = useGet<ICamerasResponse>(api.cameras, {
		params: { organisationId: organisation?.id, pageNum: 1, perPage: 100 },
	});

	return (
		<LayoutContext.Provider
			value={{
				$content,
				contentId,
			}}
		>
			<Stack sx={{ height: '100dvh' }}>
				<Header>
					<HeaderCrumbs>
						<HeaderCrumb
							icon={<FontAwesomeIcon icon={['fasr', 'camera-security']} />}
							text={camera?.name ?? ''}
							isLoading={isLoading || isLoadingCameras}
							options={cameras?.results
								?.filter((filterCamera) => filterCamera.name !== camera?.name)
								?.map((camera) => ({
									label: camera.name,
									link: `/cameras/${camera.id}`,
								}))}
						/>
					</HeaderCrumbs>
				</Header>
				<Stack direction="row" flexGrow={1} minWidth={0} minHeight={0}>
					<Sidebar>
						<SidebarItems>
							<SidebarItem
								disableActive
								name={`Back to Cameras`}
								path="/cameras"
								icon={<FontAwesomeIcon icon={['fasr', 'arrow-left-long']} />}
							/>
							<SidebarItem
								root={`/cameras/${cameraId}`}
								name="Camera"
								path={`/cameras/${cameraId}`}
								icon={<FontAwesomeIcon icon={['fasr', 'camera-security']} />}
							/>

							<SidebarItem
								disabled
								name="Analytics"
								path={`/cameras/${cameraId}/analytics`}
								icon={<FontAwesomeIcon icon={['fasr', 'chart-pie-simple']} />}
							/>

							{/*TODO: Get camera and pass locationId here*/}
							<SidebarItem
								name="Location"
								path={`/locations/${camera?.location?.id}`}
								icon={<FontAwesomeIcon icon={['fasr', 'location']} />}
							/>
						</SidebarItems>

						<SidebarItems bottom>
							<SidebarItem
								name="Support"
								path="/support"
								icon={<FontAwesomeIcon icon={['fasr', 'life-ring']} />}
							/>
							<SidebarItem
								name="Settings"
								path="/settings/profile"
								icon={<FontAwesomeIcon icon={['fasr', 'cog']} />}
							/>
						</SidebarItems>
					</Sidebar>

					<Stack
						flexGrow={1}
						minWidth={0}
						minHeight={0}
						overflow="auto"
						height="100%"
						ref={$content}
						id={contentId}
						position="relative"
						component="main"
						marginLeft={{
							xs: `calc(var(--Sidebar-width, 250px) * -1)`,
							md: 0,
						}}
					>
						<Box
							sx={{
								px: {
									xs: 2,
									md: 3,
								},
								pt: {
									xs: 1,
									md: 2,
								},
								pb: {
									xs: 2,
									sm: 2,
									md: 3,
								},
							}}
						>
							<Container disableGutters maxWidth="xxl">
								<Outlet />
							</Container>
						</Box>
					</Stack>
				</Stack>
			</Stack>
			{/* <Footer /> */}
		</LayoutContext.Provider>
	);
};

export default CameraLayout;
