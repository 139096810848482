import Box from '@mui/joy/Box';
import { useRef } from 'react';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';
import Sidebar, { SidebarItem, SidebarItems } from '~/components/Layout/Sidebar';
import Header, { HeaderCrumb, HeaderCrumbs } from '~/components/Layout/Header';
import { LayoutContext } from '~/utils/contexts';
import { Container, Stack } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '~/hooks/auth';
import { useGet } from '~/utils/api';
import { ICampaign, ICampaignsResponse } from '~/models/api/campaign';
import api from '~/utils/api/endpoints';
import { HeaderTitle } from '../Header';
import EditKioskAction from '../Kiosks/EditKioskAction';

const CampaignLayout = () => {
	const { id: campaignId } = useParams();
	const $content = useRef(null);
	const contentId = 'merlin-cloud-app-content';

	const {
		data: campaign,
		isLoading: isLoadingCampaign,
		error,
	} = useGet<ICampaign>(api.campaign, {
		params: { campaignId },
	});

	const { data: campaigns, isLoading: isLoadingCampaigns } = useGet<ICampaignsResponse>(
		api.campaigns,
		{
			params: { archived: false, sort: 'oldest', pageNum: 1, perPage: 10 },
		}
	);

	return (
		<LayoutContext.Provider
			value={{
				$content,
				contentId,
			}}
		>
			<Stack sx={{ height: '100dvh' }}>
				<Header>
					<HeaderCrumbs>
						<HeaderCrumb
							icon={<FontAwesomeIcon icon={['fasr', 'files']} />}
							text={campaign?.name ?? ''}
							isLoading={isLoadingCampaign || isLoadingCampaigns}
							options={campaigns?.results
								?.filter((filterCampaign) => filterCampaign.id !== campaign?.id)
								?.map((campaign) => ({
									label: campaign.name,
									link: `/campaigns/${campaign.id}`,
								}))}
						/>
					</HeaderCrumbs>
				</Header>
				<Stack direction="row" flexGrow={1} minWidth={0} minHeight={0}>
					<Sidebar>
						<SidebarItems>
							<SidebarItem
								disableActive
								name={`Back to Campaigns`}
								path="/campaigns"
								icon={<FontAwesomeIcon icon={['fasr', 'arrow-left-long']} />}
							/>
						</SidebarItems>

						<SidebarItems bottom>
							<SidebarItem
								name="Support"
								path="/support"
								icon={<FontAwesomeIcon icon={['fasr', 'life-ring']} />}
							/>
							<SidebarItem
								name="Settings"
								path="/settings/profile"
								icon={<FontAwesomeIcon icon={['fasr', 'cog']} />}
							/>
						</SidebarItems>
					</Sidebar>

					<Stack
						flexGrow={1}
						minWidth={0}
						minHeight={0}
						overflow="auto"
						height="100%"
						ref={$content}
						id={contentId}
						position="relative"
						component="main"
						marginLeft={{
							xs: `calc(var(--Sidebar-width, 250px) * -1)`,
							md: 0,
						}}
					>
						<Box
							sx={{
								px: {
									xs: 2,
									md: 4,
								},
								pt: {
									xs: 1,
									md: 2,
								},
								pb: {
									xs: 2,
									sm: 2,
									md: 3,
								},
							}}
						>
							<Container disableGutters maxWidth="xxl">
								<Outlet />
							</Container>
						</Box>
					</Stack>
				</Stack>
			</Stack>
			{/* <Footer /> */}
		</LayoutContext.Provider>
	);
};

export default CampaignLayout;
