import Box from '@mui/joy/Box';
import { useRef } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import Sidebar, { SidebarItem, SidebarItems } from '~/components/Layout/Sidebar';
import Header, { HeaderCrumb, HeaderCrumbs } from '~/components/Layout/Header';
import { LayoutContext } from '~/utils/contexts';
import { Container, Stack } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '~/hooks/auth';
import { useGet } from '~/utils/api';
import { ILocation, ILocationsResponse } from '~/models/api/location';
import api from '~/utils/api/endpoints';

const LocationLayout = () => {
	const { organisation } = useAuth();
	const { id: locationId } = useParams();
	const $content = useRef(null);
	const contentId = 'merlin-cloud-app-content';

	const {
		data: location,
		isLoading: isLoadingLocation,
		error,
	} = useGet<ILocation>(api.locations.location, {
		params: { locationId },
	});

	const { data: locations, isLoading: isLoadingLocations } = useGet<ILocationsResponse>(
		api.locations.locations,
		{
			params: { organisationId: organisation?._id, pageNum: 1, perPage: 100 },
		}
	);

	return (
		<LayoutContext.Provider
			value={{
				$content,
				contentId,
			}}
		>
			<Stack sx={{ height: '100dvh' }}>
				<Header>
					<HeaderCrumbs>
						<HeaderCrumb
							icon={<FontAwesomeIcon icon={['fasr', 'location']} />}
							text={location?.name ?? ''}
							isLoading={isLoadingLocation || isLoadingLocations}
							options={locations?.results
								?.filter((filterLocation) => filterLocation.name !== location?.name)
								?.map((loc) => ({
									label: loc.name,
									link: `/locations/${loc.id}`,
								}))}
						/>
					</HeaderCrumbs>
				</Header>
				<Stack direction="row" flexGrow={1} minWidth={0} minHeight={0}>
					<Sidebar>
						<SidebarItems>
							<SidebarItem
								disableActive
								name={`Back to Locations`}
								path="/locations"
								icon={<FontAwesomeIcon icon={['fasr', 'arrow-left-long']} />}
							/>
							<SidebarItem
								name="Location"
								root={`/locations/${locationId}`}
								path={`/locations/${locationId}`}
								icon={<FontAwesomeIcon icon={['fasr', 'location']} />}
							/>{' '}
							<SidebarItem
								disabled
								name="Analytics"
								path={`/locations/${locationId}/analytics`}
								icon={<FontAwesomeIcon icon={['fasr', 'chart-pie-simple']} />}
							/>
							<SidebarItem
								name="Cameras"
								path={`/locations/${locationId}/cameras`}
								icon={<FontAwesomeIcon icon={['fasr', 'camera-security']} />}
							/>
							<SidebarItem
								disabled
								name="Room"
								path={`/locations/${locationId}/room`}
								icon={<FontAwesomeIcon icon={['fasr', 'building']} />}
							/>
						</SidebarItems>

						<SidebarItems bottom>
							<SidebarItem
								name="Support"
								path="/support"
								icon={<FontAwesomeIcon icon={['fasr', 'life-ring']} />}
							/>
							<SidebarItem
								name="Settings"
								path="/settings/profile"
								icon={<FontAwesomeIcon icon={['fasr', 'cog']} />}
							/>
						</SidebarItems>
					</Sidebar>

					<Stack
						flexGrow={1}
						minWidth={0}
						minHeight={0}
						overflow="auto"
						height="100%"
						ref={$content}
						id={contentId}
						position="relative"
						component="main"
						marginLeft={{
							xs: `calc(var(--Sidebar-width, 250px) * -1)`,
							md: 0,
						}}
					>
						<Box
							sx={{
								px: {
									xs: 2,
									md: 4,
								},
								pt: {
									xs: 1,
									md: 2,
								},
								pb: {
									xs: 2,
									sm: 2,
									md: 3,
								},
							}}
						>
							<Container disableGutters maxWidth="xxl">
								<Outlet />
							</Container>
						</Box>
					</Stack>
				</Stack>
			</Stack>
			{/* <Footer /> */}
		</LayoutContext.Provider>
	);
};

export default LocationLayout;
