import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Box,
	Divider,
	Dropdown,
	IconButton,
	List,
	ListDivider,
	ListItem,
	ListItemContent,
	ListItemDecorator,
	Menu,
	MenuButton,
	MenuItem,
	Option,
	Select,
	Skeleton,
	Stack,
	Typography,
	useTheme,
} from '@mui/joy';
import Avatar from '@mui/joy/Avatar';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { AnimateKeyframes } from 'react-simple-animate';
import { useAuth } from '~/hooks/auth';
import { IOrganisation } from '~/models/api/organisation';
import { altKeyMapping } from '~/utils/common';
import { useSidebar } from '~/utils/contexts';
import UserMenu from './UserMenu';
import { ColorSchemeToggle } from '../ConversionLayout';

interface IHeader {
	children?: React.ReactNode;
}

interface IHeaderCrumbs {
	children?: React.ReactNode;
	crumbsFromPathname?: {
		enabled?: boolean;
		slug?: string;
	};
}

interface IHeaderCrumb {
	icon?: React.ReactNode;
	isLoading?: boolean;
	text: string;
	options?: { label: string; link: string }[];
}

export const HeaderCrumbs: React.FC<IHeaderCrumbs> = (props) => {
	return (
		<Stack
			direction="row"
			spacing={2}
			alignItems="center"
			sx={(theme) => ({
				'.MuiSelect-startDecorator': {
					color: theme.vars.palette.text.tertiary,
				},
				'.MuiSelect-indicator': {
					svg: { color: theme.vars.palette.text.tertiary },
				},
				'.header-crumb:last-child': {
					color: theme.vars.palette.text.primary,
					'.MuiSelect-startDecorator': {
						color: theme.vars.palette.text.primary,
					},
					'.MuiSelect-indicator': {
						svg: { color: theme.vars.palette.text.primary },
					},
				},

				button: { opacity: '1 !important', fontWeight: 500 },
			})}
		>
			{props.children}
		</Stack>
	);
};

export const HeaderCrumb: React.FC<IHeaderCrumb> = (props) => {
	const [open, setOpen] = useState(false);

	const oneItem = !props.options?.length; //This is 0 as we should filter out the current item from the list

	const Crumb = (
		<Select
			variant="plain"
			className="header-crumb"
			startDecorator={!!props.icon && props.icon}
			indicator={<FontAwesomeIcon icon={['fass', 'caret-down']} />}
			placeholder={props.isLoading ? 'loading text' : props.text}
			sx={(theme) => ({
				ml: '4px !important',
				fontSize: theme.vars.fontSize.lg,
				'&:hover': {
					color: 'text.primary',
					'.MuiSelect-startDecorator': {
						color: theme.vars.palette.text.primary,
					},
					'.MuiSelect-indicator': {
						svg: { color: theme.vars.palette.text.primary },
					},
				},
				'.MuiSelect-indicator': {
					display: !props.text?.length ? 'none' : 'block',
				},

				color: theme.vars.palette.text.tertiary,
				border: 'none',
				backgroundColor: 'transparent',
				boxShadow: 'none',
			})}
		>
			{props.options?.map((option, index) => (
				<Link key={index} to={`${option.link}`}>
					<Option value={option.link}>{option.label}</Option>
				</Link>
			))}
		</Select>
	);

	if (props.isLoading) {
		return (
			<>
				<Typography textColor="divider">/</Typography>
				<Skeleton variant="text" width={100} height={24} />
			</>
		);
	}

	if (!props.text?.length) {
		return null;
	}

	if (oneItem)
		return (
			<>
				<Typography textColor="divider">/</Typography>
				<Typography startDecorator={!!props.icon && props.icon} level="title-lg">
					{props.text}
				</Typography>
			</>
		);

	return (
		<>
			<Typography textColor="divider">/</Typography>
			{Crumb}
		</>
	);
};

const Header: React.FC<IHeader> = (props) => {
	const theme = useTheme();
	const { user } = useAuth();

	const navigate = useNavigate();

	const [menuIndex, setMenuIndex] = React.useState<null | number>(null);
	const itemProps = {
		onClick: () => setMenuIndex(null),
	};
	const createHandleLeaveMenu = (index: number) => (getIsOnButton: () => boolean) => {
		setTimeout(() => {
			const isOnButton = getIsOnButton();
			if (!isOnButton) {
				setMenuIndex((latestIndex: null | number) => {
					if (index === latestIndex) {
						return null;
					}
					return latestIndex;
				});
			}
		}, 200);
	};
	return (
		<>
			<Stack
				direction="row"
				sx={{
					backgroundColor: theme.vars.palette.background.body,
					position: 'relative',
					zIndex: 3,
				}}
			>
				<Stack direction="row" spacing={2} py={2} px={3} flexGrow="1" alignItems="center">
					<HeaderSidebarToggle />
					<RouterLink style={{ height: 24, width: 'auto' }} to="/">
						<Box
							height="100%"
							width="100%"
							component="img"
							alt="Logo"
							src="/assets/logo-icon.svg"
						/>
					</RouterLink>
					<Typography level="title-sm" component="span" textColor="neutral.600">
						/
					</Typography>
					<div>
						<HeaderOrganisationButton />
					</div>
					<Box sx={{ flex: 1 }}>{props.children}</Box>
					<UserMenu>
						<MenuItem component={RouterLink} to="/logout">
							Logout
						</MenuItem>
						<MenuItem>
							<ColorSchemeToggle dataType="button" text />
						</MenuItem>
					</UserMenu>
				</Stack>
			</Stack>
			<Divider />
		</>
	);
};

const renderShortcut = (text: string) => (
	<Typography level="body-sm" textColor="text.tertiary" ml="auto">
		{text}
	</Typography>
);

const HeaderSidebarToggle: React.FC = () => {
	const { onMobileToggle } = useSidebar();
	const theme = useTheme();

	return (
		<IconButton
			sx={{
				[theme.breakpoints.up('md')]: {
					display: 'none',
				},
			}}
			onClick={() => onMobileToggle?.()}
		>
			<FontAwesomeIcon icon={['fasr', 'bars']} />
		</IconButton>
	);
};

const HeaderOrganisationButton: React.FC = () => {
	const { organisation, organisations, selectOrganisation } = useAuth();
	const navigate = useNavigate();
	const [organisationAlreadySelected, setOrganisationAlreadySelected] = useState(false);
	const [organisationsOpen, setOrganisationsOpen] = useState(false);

	const handleOrganisationsOpen = useCallback(
		(e: React.SyntheticEvent | null, isOpen: boolean) => {
			setOrganisationsOpen(isOpen);
		},
		[]
	);

	useEffect(() => {
		const keyFn = (event: KeyboardEvent) => {
			const altKey = altKeyMapping[event.key];

			if (event.altKey && altKey === 'O') {
				navigate('/settings/organisations');
				return;
			}

			organisations?.forEach((organisation, i) => {
				if (event.altKey && altKey === i + 1) {
					if (organisation?.id === organisation?.id) {
						setOrganisationAlreadySelected(true);
					} else {
						selectOrganisation(organisation);
					}
				}
			});
		};

		window.addEventListener('keydown', keyFn);

		return () => {
			window.removeEventListener('keydown', keyFn);
		};
	}, [organisations]);

	return (
		<Dropdown open={organisationsOpen} onOpenChange={handleOrganisationsOpen}>
			<MenuButton
				variant="soft"
				color="neutral"
				sx={(theme) => ({
					svg: {
						color: 'text.tertiary',
					},
					p: 0,
					width: '100%',
					background: 'none',
					'&:hover': { background: 'none' },
					justifyContent: 'flex-start',
				})}
			>
				<Stack
					spacing={1}
					direction="row"
					alignItems="center"
					sx={{
						':hover': {
							svg: { color: 'text.primary' },
							'.header-organisation-button-text': { color: 'text.primary' },
						},
					}}
				>
					<Box
						sx={{
							position: 'relative',
						}}
					>
						<AnimateKeyframes
							play={organisationAlreadySelected}
							pause={!organisationAlreadySelected}
							duration={0.2}
							delay={0}
							keyframes={[
								{ 0: 'transform: rotate(0deg);' },
								{ 25: 'transform: rotate(5deg);' },
								{ 75: 'transform: rotate(-5deg);' },
								{ 100: 'transform: rotate(0deg);' },
							]}
							render={({ style: transformStyle }) => (
								<Box>
									<Avatar
										src={organisation?.logoAsset?.sizes?.[0]?.url ?? ''}
										size="sm"
										sx={(theme) => ({
											borderRadius: 8,
											border: `1px solid ${theme.vars.palette.neutral[600]}`,
											position: 'relative',
											zIndex: 3,
										})}
										style={transformStyle}
									/>
								</Box>
							)}
						/>
					</Box>

					<Stack direction="row" spacing={1} alignItems="center">
						<Typography
							className="header-organisation-button-text"
							endDecorator={<FontAwesomeIcon icon={['fass', 'caret-down']} />}
							level="title-lg"
							textColor="text.tertiary"
						>
							{organisation?.name}
						</Typography>
					</Stack>
				</Stack>
			</MenuButton>

			<HeaderOrganisationMenu />
		</Dropdown>
	);
};

const HeaderOrganisationMenu: React.FC = () => {
	const { organisations } = useAuth();

	return (
		<Menu
			sx={{ zIndex: '9999', width: `min(300px, clamp(200px, 400px, 100%))` }}
			placement="bottom-end"
		>
			<ListItem nested>
				<List aria-label="Select an Organisation">
					{organisations?.map((organisation, i) => (
						<HeaderOrganisationMenuItem
							key={organisation?.id ?? i}
							organisation={organisation}
							index={i}
						/>
					))}
				</List>
			</ListItem>
			<ListDivider />
			<ListItem nested>
				<List aria-label="Other options">
					<MenuItem component={Link} to="/settings/organisations">
						Manage Organisations{' '}
						{renderShortcut(
							`${navigator.userAgent.indexOf('Mac OS X') != -1 ? '⌥' : 'Alt'} O`
						)}
					</MenuItem>
				</List>
			</ListItem>
		</Menu>
	);
};

interface IHeaderOrganisationMenuItemProps {
	organisation: IOrganisation;
	index: number;
}

const HeaderOrganisationMenuItem: React.FC<IHeaderOrganisationMenuItemProps> = (props) => {
	const navigate = useNavigate();
	const { selectOrganisation, organisation: activeOrganisation } = useAuth();
	const { organisation } = props;

	return (
		<MenuItem
			onClick={() => {
				navigate('/');
				selectOrganisation(organisation);
			}}
		>
			<ListItemDecorator>
				<Avatar
					src={organisation?.logoAsset?.sizes?.[0]?.url ?? ''}
					size="sm"
					sx={(theme) => ({
						borderRadius: 8,
						'--Avatar-size': '2.5rem',
						position: 'relative',
						zIndex: 3,
						border:
							activeOrganisation?.id === organisation?.id
								? `2px solid ${theme.vars.palette.neutral[300]}`
								: '2px solid transparent',
						[theme.getColorSchemeSelector('light')]: {
							border:
								activeOrganisation?.id === organisation?.id
									? `2px solid ${theme.vars.palette.neutral[400]}`
									: '2px solid transparent',
						},
						transition: '.3s ease-in-out border',
					})}
				/>
			</ListItemDecorator>
			<ListItemContent
				sx={{
					paddingLeft: 2,
				}}
			>
				<Typography level="title-sm">{organisation.name}</Typography>
				<Typography level="body-sm" noWrap>
					{organisation?.users?.length} Member
					{organisation?.users?.length > 1 ? 's' : ''}
				</Typography>
			</ListItemContent>
			<Typography level="body-sm" sx={{ fontWeight: 'bold', color: 'inherit' }}>
				{renderShortcut(
					`${navigator.userAgent.indexOf('Mac OS X') != -1 ? '⌥' : 'Alt'} ${
						props.index + 1
					}`
				)}
			</Typography>
		</MenuItem>
	);
};

export default Header;
