import Avatar from '@mui/joy/Avatar';
import Dropdown from '@mui/joy/Dropdown';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import * as React from 'react';
import { useAuth } from '~/hooks/auth';

export default function UserMenu(props: { children: React.ReactNode; visible?: boolean }) {
	const { user } = useAuth();

	const { visible = true } = props;

	if (!visible) return null;
	return (
		<Dropdown>
			<MenuButton
				slots={{ root: IconButton }}
				slotProps={{ root: { variant: 'plain', color: 'neutral' } }}
				sx={{ borderRadius: 40 }}
			>
				<Avatar src={user?.avatar?.sizes?.[0]?.url} alt={user?.firstName} />
			</MenuButton>
			<Menu
				variant="solid"
				invertedColors
				aria-labelledby="user-menu"
				sx={{
					'--List-padding': '0.5rem',
					'--ListItemDecorator-size': '3rem',
				}}
			>
				{props.children}
			</Menu>
		</Dropdown>
	);
}
